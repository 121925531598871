
import { defineComponent, reactive, toRefs, ref } from 'vue';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
import { changePasswordRequest } from '@/modules/requests';

export default defineComponent({
  setup: () => {
    const { basicForm } = resource();
    const { validateForm } = validation();

    const successModal = ref<any>(null);
    const loading = ref<boolean>(false);

    const data = reactive({
      form: basicForm([
        {
          name: 'current_password',
          rules: 'required',
        },
        {
          name: 'new_password',
          rules: 'required',
        },
        {
          name: 'password_confirmation',
          rules: 'required|same:new_password',
        },
      ]),
    });

    function changePassword() {
      if (!validateForm(data.form)) return false;
      loading.value = true;
      changePasswordRequest(
        data.form.data.current_password.value,
        data.form.data.new_password.value,
        data.form.data.password_confirmation.value,
      )
        .then(successModal.value.open)
        .catch((error) => console.log(error))
        .finally(() => (loading.value = false));
    }

    return {
      changePassword,
      ...toRefs(data),
      successModal,
      loading,
    };
  },
});
