<template>
  <div class="animated reveal">
    <div class="mb-8 text-2xl font-bold text-invest-primary">
      Change Password
    </div>

    <form @submit.prevent="changePassword">
      <div class="sm:w-4/12">
        <form-input
          name="current_password"
          :form="form"
          v-model="form.data.current_password.value"
          type="password"
        >
          Current Password
        </form-input>
        <form-input
          name="new_password"
          type="password"
          :form="form"
          v-model="form.data.new_password.value"
        >
          New Password
        </form-input>
        <form-input
          type="password"
          name="password_confirmation"
          :form="form"
          v-model="form.data.password_confirmation.value"
        >
          Confirm New Password
        </form-input>
        <div class="mt-12">
          <button class="btn btn-dark" :disabled="loading">
            <loader v-if="loading" />
            <span v-else>Change password</span>
          </button>
        </div>
      </div>
    </form>
  </div>
  <alert-modal ref="successModal">
    <template #subtitle> Password Changed Successfully </template>
    <template #body>
      <router-link
        :to="{ name: 'Dashboard' }"
        class="mt-5 pt-3 btn btn-primary"
      >
        Okay
      </router-link>
    </template>
  </alert-modal>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, ref } from 'vue';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
import { changePasswordRequest } from '@/modules/requests';

export default defineComponent({
  setup: () => {
    const { basicForm } = resource();
    const { validateForm } = validation();

    const successModal = ref<any>(null);
    const loading = ref<boolean>(false);

    const data = reactive({
      form: basicForm([
        {
          name: 'current_password',
          rules: 'required',
        },
        {
          name: 'new_password',
          rules: 'required',
        },
        {
          name: 'password_confirmation',
          rules: 'required|same:new_password',
        },
      ]),
    });

    function changePassword() {
      if (!validateForm(data.form)) return false;
      loading.value = true;
      changePasswordRequest(
        data.form.data.current_password.value,
        data.form.data.new_password.value,
        data.form.data.password_confirmation.value,
      )
        .then(successModal.value.open)
        .catch((error) => console.log(error))
        .finally(() => (loading.value = false));
    }

    return {
      changePassword,
      ...toRefs(data),
      successModal,
      loading,
    };
  },
});
</script>
